import React from 'react';

export default function Gold(props) {
  let circles=[];
  let cxm=[];//4,5,6,8,4.5,6,4.4,6,5,7, 8,9,10,4];
  let cxo=[];//-100,10,200,380,550,740,1000,1200,1450,1710, 1900];
  let cyo=[];//0,40,80,50,60,50,40,25,10,60, 50,40,30,40];
  let cxx=[];
  let cyy=[];
  for(let i=0;i<10;i++) {
      cxm.push(Math.random()*4+3);
      cxo.push(i*200+Math.random()*40-200);
      cyo.push(-100-(Math.random()*40+10)*3);
      cxx.push((Math.random()-.5)*10);
      cyy.push((Math.random()-.5)*10);
  }
  for(let i=0;i<cxo.length;i++) {
      for(var a=45;a<85;a++) {
        let vals=a*cxm[i]+";"+a*cxm[i]*2+";"+a*cxm[i];
        let vals2=cxo[i]+";"+(cxo[i]+cxx[i])+";"+cxo[i];
        let vals3=cyo[i]+";"+(cyo[i]+cyy[i])+";"+cyo[i];
        
          circles.push(<circle cx={cxo[i]} cy={cyo[i]} r={a*cxm[i]} stroke="#D2691E" stroke-width=".45" fill="none">
            <animate attributeName="r" from={a*cxm[i]} to={a*cxm[i]*2} dur="200s" repeatCount="indefinite" 
              values={vals} keyTimes="0; 0.5; 1"
            />
            <animate attributeName="cx" from={cxo[i]} to={cxo[i]+cxx[i]} dur="200s" repeatCount="indefinite" 
              values={vals2} keyTimes="0; 0.5; 1"
            />
            <animate attributeName="cy" from={cyo[i]} to={cyo[i]+cyy[i]} dur="200s" repeatCount="indefinite" 
              values={vals3} keyTimes="0; 0.5; 1"
            />

          </circle>);
      }
  }
  return (
        <svg
        	xmlns="http://www.w3.org/2000/svg"
        	version="1.1"
        	width="2000"
        	height="60">
        	<g id="background">
        		<rect width="2000" height="60" fill="#F0E68C" />
        	</g>
        	<g id="dots">
                {circles}
        	</g>
        </svg>
  );
}
