/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import withWrapper from '../components/wrapper.js';
import pen_background from '../images/quill4.png';
import scott from '../images/scott_halftone_wbanner.png';
import secBand from '../images/secBand.svg';
import blotch from '../images/inkBlotch.svg';
import shimmer from '../images/shimmer.svg';
import Guilloche from "../components/gold.js";
import { renderToStaticMarkup } from 'react-dom/server';
import { Helmet } from "react-helmet";


function _inner (props) {
  let sec="url(" + secBand + ")";
  let pen="url(" + pen_background + ")";
  let ink="url(" + blotch + ")";
  let shim = "url(" + shimmer + ")";
  const svgString = encodeURIComponent(renderToStaticMarkup(<Guilloche />));
  const dataUri = `url("data:image/svg+xml,${svgString}")`;
  let h1Style= {
//    backgroundImage: dataUri,
//      backgroundImage: shim
    backgroundColor: '#111'
  };
  function genVT() {
    //this function generates little VT elements
    var o=[];
    var l=0;
    var t=0;
    for(var i=0;i<50;i++) {
      l+=Math.random()*50;
      t+=Math.random()*4;
      o.push(<div style={{position:'absolute',top:t+'%',left:l%100+'%'}}>∞</div>);
    }
    return (
      <div style={{position:'absolute',width:'30%',left:'5px',bottom:'60px',top:'10px',color:'rgb(252, 218, 9)',fontSize:'17px'}}>
        {o}
      </div>
      );
  }
  
  //maybe how we get to gold
  //https://www.creativebloq.com/netmag/how-go-beyond-basics-svg-filters-71412280

//<div class="underlay" style={{opacity:'1',zIndex:"0",position:'absolute',top:'0',bottom:'0',left:'0',right:'0',backgroundImage:shim,backgroundPosition: '0 0',backgroundRepeat: 'no-repeat'}}></div>
//ink blot
//  <div class="underlay" style={{opacity:'.6',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0',backgroundImage:ink,backgroundSize: '450px 630px',backgroundRepeat: 'no-repeat',backgroundPosition: '-100px 50px'}}></div>

  return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>About</title>
            <link rel="canonical" href="https://www.valtrace.com/about/" />
            <html lang="en" />
          </Helmet>

          <div class="underlay" style={{opacity:'.5',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0'}}>
            {genVT(100)}
          </div>
          
          <div class="underlay" style={{opacity:'1',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0',backgroundImage:pen,backgroundSize: '330px',backgroundRepeat: 'no-repeat',backgroundPosition: 'left top'}}></div>
          <div class="underlay" style={{opacity:'1',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0',backgroundImage:sec,backgroundPosition: '96% center',backgroundRepeat: 'repeat-y'}}></div>
          <div>
            <h1><span className='hsp Gold' style={h1Style}>ABOUT</span><span className='hsp2'>Updated on: Sunday, 14 March, 2021</span></h1>
          </div>
          <div>
            <div style={{clear:'both',float: 'left',marginTop: '20px',marginBottom: '20px',marginRight: '20px', width: '35%'}} >
              <img alt='Scott' src={scott} style={{width:'100%',margin:'0'}} />
              <div style={{textAlign:'center',width:'100%',fontSize:'80%',lineHeight: '1.3em'}}>
                <span style={{fontWeight:'500'}}>Scott</span>
                <span> has been working on metrics for over a decade.  He created this tool to make executive decision making easier.</span>
              </div>
            </div>
            <p>Valtrace uses artificial intelligence to value companies and predict future financials.</p>
            <p>We trained and tested our neural networks on public companies, so we know it works well.</p>
            <p>We use a illiquidity discount of 20% in our valuation estimates for private companies.  We hope in the future to improve our estimate.</p>
            <p>These networks can then be used to estimate the worth of private companies.</p>
            <div style={{padding:'10px'}}>
              <center><a href='/createCompany' style={{textDecoration: 'none'}}><div style={{display: 'inline-block',border: '1px solid rgba(255,255,255,.75)', borderRadius: '4px', backgroundColor: 'black', color: 'white', fontSize:'20px',padding: '10px 35px',margin:'0px'}}>Value a Business</div></a></center>
              <div style={{color:'black',fontSize:'22px'}}><center>...it's free!</center></div>
            </div>

            <h2>Value Trace Uses</h2>
            <p>Valuations can be used in a number of situations.</p>
            <h4>Venture Capitalists</h4>
            <p>If venture capitalists insist that the companies that they invest in have setup value tracability, and they gain access to the dashboard, they help both themselves and their companies make better decisions.</p>
            <div style={{display:'inline-block'}}>
              <ul>
  	            <li>Determining the value of the initial raise</li>
                <li>Clarify how well a funded company is doing</li>
  	            <li>Making it easier for funded companies to raise additional rounds</li>
  	            <li>Act as an influencer for a secondary market</li>
  	            <li>Help funded companies raise money in the form of debt</li>
  	            <li>Valuations for stock options</li>
  	          </ul>
  	        </div>
    	      <h4>Private Companies</h4>
    	      <p>Private companies benefit from metric traceability.  They often have long financial histories that can provide a good estimate of the company's value.</p>
            <div style={{margin:'0 0px'}}>
  	          <ul>
                <li>Use for loans</li>
                <li>Use for sale</li>
                <li>Financial predictions</li>
                <li>Decision making</li>
                <li>Valuation for employee stock ownership plan</li>
                <li>Establishing  partnership percentages</li>
              </ul>
            </div>
  	        <h2>Site Technical Description</h2>
            <p>The static portions of the app are written in React and served with Netlify.
            Our API runs on AWS Lambda and uses Python Keras/Tensorflow for deep learning.
            Continuous deployment is accomplished with a GIT push. AWS Cognito is used for authentication.
            Company financial data is stored on AWS DynamoDB and GraphQL is used to pull from other data sources.</p>
  	        <h2>Contact</h2>
            <p>If you want to leave feedback or get in touch with us you can email us at: contact@valtrace.com</p>
          </div>
          
        </div>
  );
}
let bgText="url(" + secBand + "), url(" + pen_background + ")";
let bgStyle= {
    backgroundImage: bgText,
    backgroundPosition: '94% center,right bottom, center',
    backgroundRepeat: 'repeat-y,no-repeat,repeat',
    backgroundSize: 'auto,400px,auto'
};

export default withWrapper(_inner,{});